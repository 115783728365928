<template>
  <component
    :is="UIButtonPrimary"
    type="button"
    class="button-lg flex-center gap-1 !max-w-full mt-8"
    @click="userModule.startTelegramAuthorize"
  >
<!--    <Icon name="mingcute:telegram-fill" width="20" height="20" />-->
    <span class=" text-white text-[18px] flex pt-2">
      <i class="fi fi-brands-telegram"></i>
    </span>
    <slot>Через Telegram</slot>
  </component>
</template>

<script setup lang="ts">
//import { ElMessage, ElMessageBox } from 'element-plus';
import UIButtonPrimary from '~/components/UI/button/Primary.vue';
import UIButtonSecondary from '~/components/UI/button/Secondary.vue';

import { useUserStore } from '~/store/UserModule';
const userModule = useUserStore();


// import { LoginScreenContext } from '~/interfaces/login/UI';
// import AuthService from '~/services/AuthService';

// const emits = defineEmits(['authorized']);

// let pollingInterval: NodeJS.Timeout | null = null;

// const clearPollingInterval = () => {
//   if (pollingInterval) clearInterval(pollingInterval);
// };

// const closePollMessage = () => {
//   (document.querySelector('.el-message-box .el-button--primary') as HTMLButtonElement)?.click();
// };

// const pollTelegramAuthorize = async () => {
//   try {
//     await AuthService.checkTelegramAuthorize();
//     closePollMessage();
//     clearPollingInterval();
//     if (props.context === LoginScreenContext.null) {
//       navigateTo('/orders');
//     } else {
//       emits('authorized');
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

// const initPollingInterval = () => {
//   clearPollingInterval();

//   const POLLING_INTERVAL = 1500;
//   pollingInterval = setInterval(pollTelegramAuthorize, POLLING_INTERVAL);
// };

// const pollingMessage = () => {
//   ElMessageBox({
//     title: 'Вход через Telegram',
//     message: 'Перейдите в Telegram и нажмите start в боте',
//     cancelButtonText: 'Отменить',
//     showCancelButton: true,
//   }).catch(clearPollingInterval);
// };

// const startTelegramAuthorize = async () => {
//   clearPollingInterval();
//   try {
//     await AuthService.generateAuthKey();
//     pollingMessage();
//     initPollingInterval();
//   } catch {
//     ElMessage({
//       type: 'error',
//       message: 'Не удалось авторизоваться через Telegram. Пожалуйста, войдите через email',
//       duration: 30,
//     });
//     clearPollingInterval();
//   }
// };

// onBeforeUnmount(clearPollingInterval);
</script>
