<template>
  <div class="auth flex flex-col items-center">
    <h1 v-if="context !== LoginScreenContext.order">Войти в Data light</h1>
    <LoginEmailAuthorize
      v-if="[LoginMode.AUTH_EMAIL, LoginMode.REGISTER_EMAIL].includes(mode)"
      :context="context"
      :mode="mode"
      @authorized="$emit('authorized', $event)"
      class="w-full mt-8"
    />
    <LoginTelegramAuthorizeForm
      v-if="mode === LoginMode.AUTH_TELEGRAM"
      :context="context"
      @set-mode="setMode"
      @authorized="$emit('authorized', $event)"
      class="w-full"
    />
  </div>
</template>

<script setup lang="ts">
import useSetState from "~/composables/UI/useSetState";
import { LoginMode, LoginScreenContext } from "~/interfaces/login/UI";
import { useUserStore } from "~/store/UserModule";
/* eslint-disable no-undef */
interface Props {
  context?: LoginScreenContext;
  confirmedEmail?: boolean | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  context: LoginScreenContext.null,
  confirmedEmail: undefined,
});

defineEmits(["authorized"]);

const [mode, setMode] = useSetState(
  props.confirmedEmail ? LoginMode.AUTH_EMAIL : LoginMode.AUTH_TELEGRAM
);

const userModule = useUserStore();

onMounted(() => {
  userModule.requestAuthKey();
});
</script>
<style lang="scss">
.auth {
  transition: none;
  width: 100% !important;
  align-self: center;
  padding: 32px;
  margin: 0 !important;
  text-align: center;

  &,
  .col-gap-3 {
    justify-content: center;
    align-items: center;

    label {
      align-self: baseline;
    }
  }

  .col-gap-3 .col-gap-3 {
    max-width: 370px;
  }

  h1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
  }

  .auth-form {
    input {
      width: 100%;
    }

    .button-secondary {
      width: 100%;
    }
  }

  :deep(.el-dialog) {
    .el-dialog__body {
      width: 100%;
    }
  }

  @media (max-width: 578px) {
    span {
      width: fit-content;
    }
  }
}

.authorize-modal {
  .el-dialog {
    --el-dialog-border-radius: 16px;

    .el-dialog__header {
      padding-right: 0;

      &.show-close {
        padding-right: 0;
      }
    }

    .el-dialog__body {
      width: 100%;
    }
  }

  .screen-wrapper {
    max-width: fit-content;
    border: none;
    padding: 1rem;
    margin: auto;

    @media (max-width: 578px) {
      margin: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
